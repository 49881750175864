$('.hero-homepage .fade-carousel').slick({
	dots: false,
	infinite: true,
	speed: 500,
	fade: true,
	cssEase: 'linear',
	autoplay: true,
	autoplaySpeed: 7000,
	pauseOnHover: false,
	prevArrow: '<button type="button" class="slick-prev"><i class="icon-left-chevron-circled" aria-hidden="true"></i></button>',
	nextArrow: '<button type="button" class="slick-next"><i class="icon-right-chevron-circled" aria-hidden="true"></i></button>'

});

